import {LocationExplorer} from "@components/LocationExplorer/index";
import {useRouter} from "next/router";
import {useTranslation} from "ni18n";
import React from "react";
import {useTypedSelector} from "src/store";
import {RegionSlug} from "src/store/types";

import {isSaasLocation} from "../../../constants/locations";
import {Pathname, v5Pages} from "../../_common/_constants";
import {useUserSelectedLocation} from "../../../utils/browser-storage/userSelectedLocation";

export const EXCLUDED_PAGES: Pathname[] = [
  // Location Index
  v5Pages.locations,

  // Partner Pages
  v5Pages.covid19TravelClearance,
  v5Pages.partners,
  v5Pages.collegesOfFenway,

  // Care Discovery
  v5Pages.getCareSlug,
  v5Pages.testpage,

  v5Pages.careers,
];

export const LocationExplorerContainer: React.FC = () => {
  const i18n = useTranslation();
  const {locations} = useTypedSelector(({config}) => config);
  const {regionSlug: selectedRegion} = useUserSelectedLocation();

  const {pathname, query} = useRouter();
  const isLocationPage = pathname === v5Pages.clinicDetails;

  let currentLocationId;
  let initialRegion = selectedRegion;
  let isSlotTimeVisible = true;
  let headerText = i18n.t("Explore our locations");
  const currentLocation = locations.find(({slug}) => slug === query.slug);

  if (isLocationPage) {
    if (currentLocation) {
      currentLocationId = currentLocation.id;
      initialRegion = currentLocation.region.slug as RegionSlug;
    }

    isSlotTimeVisible = true;
    headerText = i18n.t("Explore other nearby locations");
  }

  const isSaas = currentLocation ? isSaasLocation(currentLocation) : false;
  const locationsToRender = isSaas
    ? locations?.filter(l => l.practiceId === currentLocation?.practiceId)
    : locations;
  if (!locationsToRender || locationsToRender.length === 0) return null;
  if (!selectedRegion) return null;

  return (
    <LocationExplorer
      locations={locationsToRender}
      headerText={headerText}
      initialRegionSlug={initialRegion}
      // @ts-expect-error TS2322: Type 'string | undefined' is not assignable to type 'string'.
      currentLocationId={currentLocationId}
      isSlotTimeVisible={isSlotTimeVisible}
    />
  );
};
